
.overlay_window {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    cursor: pointer;
}

.overlay_display {
    background: #FFFF00;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -35px 0 0 -35px;
}
.alert-enter {
    opacity: 0;
}
.alert-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}
